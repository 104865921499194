import { Formik } from "formik"
import { FamilyMembersValidation } from "./MembersValidation"
import { Grid } from "@mui/material"
import Input from "../../Common/Input"
import BasicSelect from "../../Common/Select"
import Button from "../../Common/Button"
import DatePickerComponent from "../../Common/DatePicker"
import { formatISO, parseISO } from "date-fns"
import { UploadFile } from "../../Common/UploadFile"

export const FamilyMemberAdd = ({ familyMemberInitalval, submit }) => {
    console.log(familyMemberInitalval, "familyMemberInitalval")
    return (
        <Formik
            initialValues={familyMemberInitalval}
            onSubmit={(values) => submit(values)}
            validationSchema={FamilyMembersValidation}
            enableReinitialize
        >
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                setFieldValue
            }) => (
                <Grid sx={{ display: "flex" }} container direction={"column"} width={"100%"} height={"100%"}>
                    <Grid flex={1} px={2} py={2} overflow={"auto"}>
                        <Grid container spacing={2} >
                            <Grid item xs={12} md={12}>
                                <UploadFile
                                    name="profile"
                                    onChange={(val) => setFieldValue("profile", val)}
                                    value={values?.profile || ""}
                                    labelSecondary="Upload Profile Picture"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Input
                                    id='name'
                                    name="name"
                                    label="Name *"
                                    onChange={handleChange("name")}
                                    value={values?.name || ""}
                                    error={Boolean(errors.name)}
                                    helperText={errors.name}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Input
                                    id='mobile'
                                    name="mobile"
                                    label="Mobile"
                                    onChange={handleChange("mobile")}
                                    value={values?.mobile || ""}
                                    error={Boolean(errors.mobile)}
                                    helperText={errors.mobile}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Input
                                    id='email'
                                    name="email"
                                    label="Email"
                                    onChange={handleChange("email")}
                                    value={values?.email || ""}
                                    error={Boolean(errors.email)}
                                    helperText={errors.email}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <BasicSelect
                                    size="small"
                                    value={values?.gender || "Male"}
                                    onChange={handleChange("gender")}
                                    displayEmpty
                                    label="Gender"
                                    name="gender"
                                    id="gender"
                                    items={[
                                        { label: "Male", value: "Male" },
                                        { label: "Female", value: "Female" },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DatePickerComponent
                                    id={"dob"}
                                    name={"dob"}
                                    label="Date of Birth"
                                    onChange={(val) => setFieldValue("dob", formatISO(val))}
                                    value={values?.dob ? new Date(parseISO(values?.dob)) : null}
                                    fullWidth
                                    onBlur={handleBlur}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Input
                                    id='relation'
                                    name="relation"
                                    label="Relation"
                                    onChange={handleChange("relation")}
                                    value={values?.relation || ""}
                                    error={Boolean(errors.relation)}
                                    helperText={errors.relation}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <BasicSelect
                                    size="small"
                                    value={values?.is_dependent}
                                    onChange={(val) => setFieldValue("is_dependent", val.target.value)}
                                    displayEmpty
                                    label="Is Dependent Member"
                                    name="is_dependent"
                                    id="is_dependent"
                                    items={[
                                        { label: "Yes", value: true },
                                        { label: "No", value: false },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Input
                                    id='card_number'
                                    name="card_number"
                                    label="CHSS / ID Card Number"
                                    onChange={handleChange("card_number")}
                                    value={values?.card_number || ""}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                                <Button size="large" type="submit" fullWidth={false} loading={false} onClick={() => handleSubmit()}>Save</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Formik>
    )
}