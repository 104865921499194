import React, { Fragment, useEffect } from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import DatePickerComponent from "../../../components/Common/DatePicker";
import BasicSelect from "../../../components/Common/Select";
import Button from "../../../components/Common/Button";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../store/common/commonSlice";
import { axios } from "../../../helpers/axios";
import { format } from "date-fns";
import { capitalizeFirstLetter } from "../../../helpers/utils";
import { useGetBatchDropdownQuery } from "../../../store/masters/mastersApis";
import AutoCompleteSelect from "../../../components/Common/AutoCompleteSelect";
import RenewalReport from "../../../components/admin/report-manager/RenewalReport";

const ReportsManagerContainer = ({ type: reportType }) => {

    const dispatch = useDispatch();
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [exporting, setExporting] = React.useState(false);
    const [batchData, setBatchData] = React.useState([]);
    const [selectedBatch, setSelectedBatch] = React.useState(null);
    const [renewalData, setRenewalData] = React.useState(null);

    const { isLoading, data: batch, isFetching } = useGetBatchDropdownQuery({}, { skip: reportType !== "batch-wise" });

    useEffect(() => {
        if (batch) {
            let arr = batch?.map((item) => ({ label: item.label, value: item.value }));
            setBatchData(arr);
        }
    }, [batch]);

    const fetchRenewalData = async () => {
        try {
            let response = await axios.get(`/reports/${reportType}`);
            setRenewalData(response?.result);
        } catch (e) {
            return [];
        }
    }

    useEffect(() => {
        if (reportType === "renewal") {
            fetchRenewalData();
        }
    }, [reportType]);

    const handleExport = async () => {
        try {
            if (reportType !== "renewal" && (!startDate || !endDate)) {
                dispatch(setSnackBar({
                    open: true,
                    message: "Please select report type and date range",
                    severity: "error",
                }))
                return
            }
            setExporting(true);

            let hitUrl = `/reports/${reportType}`;

            if (startDate && endDate) {
                hitUrl += `?start_date=${format(startDate, "yyyy-MM-dd")}&end_date=${format(endDate, "yyyy-MM-dd")}`;
            }

            if (selectedBatch) {
                hitUrl += `&batch_id=${selectedBatch.map(item => item.value).join(",")}`;
            }


            let response = "";

            if (reportType === "renewal") {
                response = renewalData.csv;
            } else {
                response = await axios.get(hitUrl, { responseType: "blob" });
            }

            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            if (startDate && endDate) {
                link.setAttribute('download', `${reportType}_${format(startDate, "yyyy-MM-dd")}_to_${format(endDate, "yyyy-MM-dd")}.csv`); // Filename
            } else {
                link.setAttribute('download', `${reportType}.csv`); // Filename
            }
            document.body.appendChild(link);
            link.click();

            setExporting(false);
        } catch (e) {
            setExporting(false);
        }
    }

    return (
        <Stack spacing={1}>
            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={6} sx={{ alignSelf: "center" }}>
                        <Typography variant="h6">{capitalizeFirstLetter(reportType)} Reports</Typography>
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container gap={2}>
                    {
                        reportType === "batch-wise" && (
                            <Grid item xs={12} md={3}>
                                <AutoCompleteSelect
                                    multiple={true}
                                    id="batch-autocomplete"
                                    options={batchData || []}
                                    label="Select Batches"
                                    onChange={(e, val) => setSelectedBatch(val)}
                                    value={selectedBatch || []}
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                />
                            </Grid>
                        )
                    }
                    {
                        reportType !== "renewal" && (
                            <Fragment>
                                <Grid item xs={12} md={3}>
                                    <DatePickerComponent
                                        id={"start_date"}
                                        name={"start_date"}
                                        label="Start Date"
                                        onChange={(val) => setStartDate(val)}
                                        value={startDate}
                                        fullWidth
                                        minDate={null}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <DatePickerComponent
                                        id={"end_date"}
                                        name={"end_date"}
                                        label="End Date"
                                        onChange={(val) => setEndDate(val)}
                                        value={endDate}
                                        fullWidth
                                    />
                                </Grid>
                            </Fragment>
                        )
                    }

                    {
                        reportType === "renewal" && (
                            <RenewalReport
                                data={renewalData?.filteredData || []}
                                loading={renewalData ? false : true}
                            />
                        )
                    }
                </Grid>
                <Grid container pt={2} justifyContent="flex-end" gap={2}>
                    <Button size="large" type="submit" fullWidth={false} loading={exporting} onClick={() => handleExport()}>Export & Download</Button>
                </Grid>
            </Paper>
        </Stack >
    );
}

export default ReportsManagerContainer;